<template>
  <div class="d-flex flex-column">
    <!-- Content -->
    <v-data-iterator
      v-if="admins.length"
      class="px-6 py-4"
      :items="admins"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      hide-default-footer
      disable-pagination
    >
      <!-- Header -->
      <template #header>
        <div class="d-flex justify-end">
          <!-- Sort -->
          <v-chip
            class="ma-2"
            color="white"
            text-color="secondary"
            @click="sortDesc = !sortDesc"
          >
            <v-icon class="mr-1" v-text="'sort_by_alpha'" />
            {{ $t('common.alphabetical') }}
          </v-chip>
          <!-- Search -->
          <span>
            <v-text-field
              v-model="search"
              :label="$t('common.searchByName')"
              prepend-inner-icon="search"
              tag="span"
              outlined
              dense
            />

          </span>
          <export-to-csv
            :file-name-preffix="`${organization.name} - Subscribers`"
            action-name="organization/getSubscribersCsv"
            :action-params="{ organizationId }"
            :button-text="$t('organization.exportSubscribers')"
          />
        </div>
      </template>

      <!-- Cards -->
      <template #default="props">
        <v-row>
          <v-col v-for="user in props.items" :key="user.id" cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto" max-width="344">
              <v-list-item three-line>
                <v-list-item-content class="px-8">
                  <v-avatar size="56">
                    <img v-if="user.photo" alt="Avatar" :src="user.photo">
                    <v-icon v-else x-large color="primary" v-text="'person_outline'" />
                  </v-avatar>
                  <v-list-item-title class="headline mb-1 text-center text-h6 " v-text="user.firstName" />
                  <v-list-item-subtitle class="text-center text-subtitle-3 mb-1" v-text="user.email" />
                  <v-list-item-subtitle class="text-center text-subtitle-1 mb-1" v-text="roleUser(user)" />
                </v-list-item-content>
              </v-list-item>

              <v-card-actions class="px-4 pb-4 ma-0">
                <v-spacer />
                <v-menu v-if="isUserAdmin" bottom left>
                  <template #activator="{ on, attrs }">
                    <v-btn color="primary" icon v-bind="attrs" v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="$confirm($t('organization.confirmDeleteMember')).then( (res) => res && removeStaffer(user) ) ">
                      <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <empty-list v-else :content="$t('organization.sections.people')" />
    <!-- Button Create -->
    <v-btn v-if="isUserAdmin" bottom color="primary" fab fixed right @click="showAddStaffDialog = true">
      <v-icon>add</v-icon>
    </v-btn>

    <!-- Dialogs -->
    <add-staff-dialog
      v-if="showAddStaffDialog"
      :organization-id="organizationId"
      @close-dialog="showAddStaffDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StaffList',
  components: {
    AddStaffDialog: () => import('@/modules/organization/AddStaffDialog'),
    EmptyList: () => import('@/components/EmptyList'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
  },
  props: {
    organizationId: { type: String, required: true },
  },
  data() {
    return {
      tab: null,
      showAddStaffDialog: false,
      selectedUser: null,
      search: '',
      sortBy: 'firstName',
      sortDesc: false,
    }
  },
  computed: {
    ...mapGetters({ organization: 'organization/data' }),
    ...mapGetters('organization', ['admins', 'isUserAdmin']),
  },
  methods: {
    roleUser(user) {
      if (!user) return ''
      if (user.role === 'admin') return this.$t('user.roles.admin')
      if (user.role === 'collaborator') return this.$t('user.roles.collaborator')
      return ''
    },
    removeStaffer(user) {
      this.runAsync(() => this.$store.dispatch('organization/removeStaffer', { organizationId: this.organizationId, user, role: user.role }))
    },
  },
}
</script>

<style scoped>
.v-avatar img {
  width: 100%;
  object-fit: cover;
}
</style>
